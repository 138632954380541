
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Ticket } from '@/types/om27.types';

@Component
export default class BlockingTickets extends Vue {
  @Prop({ required: true, type: Array }) tickets!: Ticket[];

  daysOpenLabel(value: number) {
    const isNumber = typeof value === 'number' && isFinite(value);
    return `${isNumber ? value.toFixed(0) : 0} DAYS`;
  }
}
