
import IconPopoverList from '@/components/gsk-components/menu/IconPopoverList.vue';
import TablePagination from '@/components/table/TablePagination.vue';
import { RouteNames } from '@/constants';
import BlockingTickets from '@/components/BlockingTickets.vue';
import SubRecordGrid from '@/components/SubRecordGrid.vue';
import { BotColumn, BotColumnData, ExpandOptions } from '@/types/logdata.types';
import { LogicalBot, LogicalBotStatuses } from '@/types/om27.types';
import { getDateRenderer } from '@/utils/datetime';
import { highlightExpandedRows } from '@/utils/table';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Location, Route } from 'vue-router';

@Component({
  components: {
    SubRecordGrid,
    IconPopoverList,
    TablePagination,
  },
})
export default class DataGrid extends Vue {
  @Prop({ required: true, type: Array }) bots!: LogicalBot[];
  @Prop({ required: true, type: Array }) selectedBots!: number[];
  @Prop({ required: true, type: String }) timeZone!: string;
  @Prop({ type: Boolean }) filterCols!: boolean;
  @Prop({ type: Boolean }) borderAround!: boolean;
  @Prop({ type: Boolean }) dense!: boolean;
  @Prop({ type: Boolean }) showAll!: boolean;
  @Prop({ type: Boolean }) showActionNeededOnly!: boolean;
  @Prop({ type: Boolean }) expandAll!: boolean;
  @Prop({ type: Boolean }) paginate!: boolean;
  @Prop({ type: Boolean }) scrollBackToBot!: boolean;
  @Prop({ type: Array, default: () => [] }) forceExpand!: number[];

  page = 1;
  perPage = 25;

  get cellSelectionOption() {
    return {
      enable: false,
    };
  }

  get classes() {
    return {
      dense: this.dense,
    };
  }

  get filterColumns(): Set<BotColumnData<LogicalBot>['field']> {
    if (!this.filterCols) {
      return new Set();
    }

    return new Set(['botName', 'status', 'actions']);
  }

  get scrollWidth() {
    if (this.filterCols) {
      return '3200px';
    }
    return '3600px';
  }

  @Watch('$route.query', { deep: true, immediate: true })
  onQueryChange(curr: Location['query'], prev: Location['query']): void {
    highlightExpandedRows(curr, prev);
  }

  get expandedRowKeys(): number[] {
    return this.forceExpand.length ? this.forceExpand : this.keys;
  }
  keys: number[] = [];

  get expandOption(): ExpandOptions {
    const render: ExpandOptions['render'] = ({ row }, h) => {
      return (
        <SubRecordGrid
          logical-bot-id={row.logicalBotId}
          time-zone={this.timeZone}
          sub-records={row.subRecords}
          filter-cols={this.filterCols}
        />
      );
    };

    return {
      render,
      expandedRowKeys: this.expandedRowKeys,
      trigger: 'cell',
      defaultExpandAllRows: this.expandAll,
      afterExpandRowChange: (exp: {
        afterExpandedRowKeys: LogicalBot['logicalBotId'][];
        row: LogicalBot;
        rowIndex: number;
      }) => {
        this.keys = exp.afterExpandedRowKeys;
      },
    };
  }

  get columns(): BotColumn<LogicalBot>[] {
    const cols: BotColumnData<LogicalBot>[] = [
      {
        field: 'botName',
        title: 'Bot Name',
        fixed: 'left',
        width: 300,
        type: 'expand',
        renderBodyCell: ({ row }, h) => {
          const { lenientAutoSignoffFlag } = row;
          let lenientEnabledLabel = null;
          if (lenientAutoSignoffFlag) {
            lenientEnabledLabel = <span class="lenient-sign-off-label">lenient</span>;
          }
          return (
            <div style="display: inline-block">
              <span data-cy='bot-name' style="word-break: break-all; cursor: pointer;">
                {row.botName}
              </span>
              { lenientEnabledLabel || '' }
            </div>
          );
        },
      },
      {
        field: 'status',
        title: 'Status',
        fixed: 'left',
        width: 200,
        renderBodyCell: ({ row }, h) => {
          const badgeType = LogicalBotStatuses[row.status as keyof typeof LogicalBotStatuses];
          let autoStatusTag, overdueDays;
          if (Array.isArray(row.statusTags)) {
            autoStatusTag = row.statusTags[0];
            overdueDays = row.statusTags[1];
          }
          if (row.status === 'Overdue') {
            return (
              <div class="bot-status bot-status--overdue">
                <div class="status">
                  <gsk-icon class="status-overdue">warning_circle</gsk-icon>
                  <span data-cy="status-text">{row.status}</span>
                </div>
                {
                  overdueDays ? (
                    <div class="status-tag">
                      <span class="ticket-status">{overdueDays}</span>
                    </div>
                  ) : ''
                }
              </div>
            );
          }
          return (
            <div class="bot-status">
              <div class="status">
                <gsk-badge dot type={badgeType} />
                <span data-cy="status-text">{row.status}</span>
              </div>
              {
                autoStatusTag ? (
                  <div class="status-tag">
                    <span class="ticket-status">{autoStatusTag}</span>
                  </div>
                ) : ''
              }
              {
                overdueDays ? (
                  <div class="status-tag">
                    <span class="ticket-status">{overdueDays}</span>
                  </div>
                ) : ''
              }
            </div>
          );
        },
      },
      {
        field: 'actions',
        title: 'Actions',
        fixed: 'left',
        width: 120,
        renderBodyCell: ({ row }, h) => {
          const enabled = !!row.signOffId;
            return (
              <div style="display: flex; align-items: center; justify-content: space-between">
                {
                  enabled
                  ? <router-link
                    to={{
                      name: RouteNames.RpaOpsSignOff,
                      params: { logicalBotId: row.logicalBotId },
                      query: this.$route.query,
                    }}
                    class="f-text-button--primary"
                    data-cy="sign-off-link"
                  >
                    Sign Off
                  </router-link>
                  : <span class="f-text-button f-disabled">Sign Off</span>
                }
                <IconPopoverList
                  props={{
                    options: [
                      {
                        key: 'hist',
                        text: 'View Sign Off History',
                        link: {
                          name: RouteNames.RpaOpsSignOffHistory,
                          params: { logicalBotId: row.logicalBotId },
                        },
                      },
                      {
                        key: 'edit',
                        text: 'Edit Logical Bot',
                        link: {
                          name: RouteNames.RpaOpsEditBot,
                          params: { logicalBotId: row.logicalBotId },
                        },
                      },
                    ],
                    icon: 'more_vertical',
                    outlined: true,
                    mini: true,
                    position: 'right',
                  }}
                />
              </div>
            );
        },
      },
      {
        field: 'sox',
        title: 'SOX',
        type: this.filterCols ? 'expand' : '',
        width: this.filterCols ? 100 : 80,
      },
      {
        field: 'folderName',
        title: 'Design/Folder Name',
        width: 300,
        renderBodyCell: ({ row }, h) => {
          return <span style="word-break: break-all;">{row.folderName}</span>;
        },
      },
      {
        field: 'schedule',
        title: 'Schedule',
        width: 300,
        renderBodyCell: ({ row }, h) => {
          const { schedule } = row;
          const parts = schedule
            .split(/(\r?\n)/gi)
            .map(s => s.trim())
            .filter(s => !!s);
          return parts.flatMap(p => [<span>{p}</span>, <br />]);
        },
      },
      {
        field: 'autoSignoffSchedule',
        title: 'Auto Sign Off Schedule',
        width: 200,
        renderBodyCell: ({ row }, h) => {
          const lines = row.autoSignoffSchedule || [];
          return <div>
            { lines.length ? lines.map((line) => <div>{ line }</div>) : '—' }
          </div>;
        },
      },
      {
        field: 'additionalInfo',
        title: 'Additional Info',
        width: 200,
      },
      {
        field: 'lenientApprover',
        title: 'Lenient Approver and Notes',
        width: 200,
      },
      {
        field: 'assignedUsers',
        title: 'Assigned Users',
        renderBodyCell: ({ row }, h) => {
          return <span>{row.assignedUsers.join(', ')}</span>;
        },
        width: 250,
      },
      {
        field: 'triggerType',
        title: 'Trigger Type',
        width: 200
      },
      {
        field: 'latestStartDate',
        title: 'Latest Start',
        renderBodyCell: this.getDateRenderer('latestStartDate'),
        width: 210,
      },
      {
        field: 'latestConfirmedStartDate',
        title: 'Latest Confirmed',
        renderHeaderCell: ({ column }, h) => {
          return <span>
            <gsk-icon
              class="status-confirmed"
              style="font-size: 12px;margin-right: 8px"
            >checkmark_outline</gsk-icon>
            <span data-cy="title">{ column.title }</span>
          </span>;
        },
        renderBodyCell: this.getDateRenderer('latestConfirmedStartDate', 'latestConfirmedStartMsg'),
        width: 250,
      },
      {
        field: 'latestErrorDate',
        title: 'Latest Error',
        renderHeaderCell: ({ column }, h) => {
          return <span>
            <gsk-icon
              class="status-overdue"
              style="font-size: 12px;margin-right: 8px"
            >warning_circle</gsk-icon>
            <span data-cy="title">{ column.title }</span>
          </span>;
        },
        renderBodyCell: this.getDateRenderer('latestErrorDate', 'latestErrorMsg'),
        width: 250,
      },
      {
        field: 'latestTrustedFinishedDate',
        title: 'Latest Successful',
        renderHeaderCell: ({ column }, h) => {
          return <span>
            <gsk-icon
              class="status-success"
              style="font-size: 12px;margin-right: 8px"
            >checkmark_filled</gsk-icon>
            <span data-cy="title">{ column.title }</span>
          </span>;
        },
        renderBodyCell: this.getDateRenderer(
          'latestTrustedFinishedDate',
          'latestTrustedFinishedMsg',
        ),
        width: 250,
      },
      {
        field: 'latestFinishDate',
        title: 'Latest Finish',
        renderBodyCell: this.getDateRenderer('latestFinishDate'),
        width: 210,
      },
      {
        field: 'tickets',
        title: 'Tickets',
        width: 270,
        renderBodyCell: ({ row }, h) => {
          return <BlockingTickets props={{ tickets: row.tickets }} />;
        },
      },
      {
        field: 'currentSignOffStartDate',
        title: 'Sign Off Start',
        renderBodyCell: this.getDateRenderer('currentSignOffStartDate'),
        width: 210,
      },
      {
        field: 'currentSignOffNeededByDate',
        title: 'Sign Off By',
        renderBodyCell: this.getDateRenderer('currentSignOffNeededByDate'),
        width: 210,
      },
    ];

    return cols
      .filter(col => !this.filterColumns.has(col.field))
      .map(col => {
        return { ...col, key: col.field, align: 'left' };
      });
  }

  getDateRenderer(
    field: Exclude<keyof LogicalBot, 'currentBlockingTicket' | 'subRecords' | 'assignedUsers'>,
    infoField:
      | Exclude<keyof LogicalBot, 'currentBlockingTicket' | 'subRecords' | 'assignedUsers'>
      | '' = '',
  ): BotColumnData<LogicalBot>['renderBodyCell'] {
    return getDateRenderer(field, this.timeZone, infoField);
  }

  filterByNeedingAction(row: LogicalBot) {
    if (this.showActionNeededOnly) {
      return row.actionNeeded;
    }
    return true;
  }

  get rowData() {
    const rows = this.bots.filter(this.filterByNeedingAction);
    if (this.showAll) {
      return rows;
    }
    const ids = new Set(this.selectedBots);
    return rows.filter(bot => ids.has(bot.logicalBotId));
  }

  get rows(): LogicalBot[] {
    return this.paginate ? this.paginatedRows : this.rowData;
  }

  get paginatedRows() {
    let start = (this.page - 1) * this.perPage;
    if (start > this.rowData.length) {
      this.page = Math.ceil(this.rowData.length / this.perPage);
      start = (this.page - 1) * this.perPage;
    }
    const end = start + this.perPage;
    return this.rowData.slice(start, end);
  }

  $refs!: {
    table?: Vue & { scrollToRowKey(obj: { rowKey: string }): void };
  };

  created() {
    if (this.scrollBackToBot) {
      const fromRoute: Route = this.$store?.state?.route?.from;
      if (fromRoute) {
        this.$nextTick(() => {
          const logicalBotId = fromRoute.params.logicalBotId;
          if (logicalBotId && this.$refs.table) {
            this.$refs.table.scrollToRowKey({ rowKey: logicalBotId });
          }
        });
      }
    }
  }
}
