
import { Component, Prop, Vue } from 'vue-property-decorator';
import { SubRecord } from '@/types/om27.types';
import { RouteNames } from '@/constants';
import { getDateRenderer } from '@/utils/datetime';
import { SubRecordColumnData } from '@/types/subrecord.types';
import { editRunner } from '@/api/om27.api'
import { RpaOpsModule } from '@/store/om27.module';


@Component({
  components: {},
})
export default class SubRecordGrid extends Vue {
  @Prop({ required: true, type: Number }) logicalBotId!: number;
  @Prop({ required: true, type: Array }) subRecords!: SubRecord[];
  @Prop({ required: true, type: String }) timeZone!: string;
  @Prop({ type: Boolean }) filterCols!: boolean;

  get filterColumns(): Set<SubRecordColumnData['field']> {
    if (!this.filterCols) {
      return new Set();
    }

    return new Set(['actions', '']);
  }

  get scrollWidth() {
    if (this.filterCols) {
      return '1000px';
    }
    return '2200px';
  }

   get rows() {
     const filteredRow = this.subRecords.filter(subrecord => subrecord.disabled)
     return this.isCorpRPAOpsDashboardVerifiers ? this.subRecords : filteredRow;
  }

  get columns(): SubRecordColumnData[] {
    const renderName: SubRecordColumnData['renderBodyCell'] = ({ row }, h) => {
      const { simpleAutoSignoffFlag } = row;
      return <div class="sub-bot-name">
        {row.fileName}
        {
          simpleAutoSignoffFlag
            ? <span class="simple-sign-off-label">1 RUN/DAY</span>
            : ''
        }
      </div>;
    };

    const cols: SubRecordColumnData[] = [
      {
        field: '',
        title: '',
        fixed: 'left',
        key: 'blank',
        width: 349,
      },
      {
        field: 'actions',
        title: 'Actions',
        fixed: 'left',
        width: 130,
        renderBodyCell: ({ row }, h) => {
          return (
            <router-link
              to={{
                name: RouteNames.RpaOpsOpenTicket,
                params: { logicalBotId: this.logicalBotId, botId: row.botId },
                query: this.$route.query,
              }}
              class="f-text-button--primary"
              data-cy="open-blocking-ticket-link"
            >
              Open Ticket
            </router-link>
          );
        },
      },
      {
        field: 'fileName',
        title: 'Task Name',
        width: this.filterCols ? 500 : 300,
        renderBodyCell: renderName,
      },
      { field: 'machineNameShort', title: 'Server', width: 150 },
      { field: '', title: '', width:100,
       renderBodyCell: ({ row }, h) => {
            return ( 
              <div class="flex-center ">
              {this.isCorpRPAOpsDashboardVerifiers && (
                 <gsk-switch label=""  checked={row.disabled}  v-on:Input={(event) => this.handleSwitchToggle(event.target.__checked, row)}></gsk-switch >
              )} 
              </div>
            );
        }},
      { field: 'botRunnerUserId', title: 'Runner', width:200,},
      {
        field: 'latestStartDate',
        title: 'Latest Start',
        renderBodyCell: this.getDateRenderer('latestStartDate'),
      },
      {
        field: 'latestConfirmedStartDate',
        title: 'Latest Confirmed Start',
        renderBodyCell: this.getDateRenderer('latestConfirmedStartDate', 'latestConfirmedStartMsg'),
      },
      {
        field: 'latestErrorDate',
        title: 'Latest Error',
        renderBodyCell: this.getDateRenderer('latestErrorDate', 'latestErrorMsg'),
        width: 250,
      },
      {
        field: 'latestTrustedFinishedDate',
        title: 'Latest Trusted Finish',
        renderBodyCell: this.getDateRenderer(
          'latestTrustedFinishedDate',
          'latestTrustedFinishedMsg',
        ),
      },
      {
        field: 'latestFinishDate',
        title: 'Latest Finish',
        renderBodyCell: this.getDateRenderer('latestFinishDate'),
      },
    ];

    return cols
      .filter(col => !this.filterColumns.has(col.field))
      .map(col => {
        return { align: 'left', key: col.field, ...col };
      });
  }
 
   get userData(){
    const userDetails = window.localStorage.getItem('userData');
    return userDetails ? JSON.parse(userDetails) : null; 
  }
  get isCorpRPAOpsDashboardVerifiers(){
     const userDetails = this.userData;
     return userDetails && userDetails.groups && userDetails.groups.includes('CorpRPAOpsDashboardVerifiers')
  }
 
  getDateRenderer(
    field: keyof SubRecord,
    info: keyof SubRecord | '' = '',
  ): SubRecordColumnData['renderBodyCell'] {
    return getDateRenderer(field, this.timeZone, info);
  }
  handleSwitchToggle(value, row){
      const updatedValue = !value
      this.editBotRunnerState(row, updatedValue)
  }
  editBotRunnerState(row, disabledValue){
    const payload = {
      disabled: disabledValue,
      bot_id: row.botId,
      bot_runner_id: row.botRunnerUserId,
      updated_by_user_mud_id: this.userData.immutable_id
    }
    RpaOpsModule.editRunner(payload)
  }
}
